<div class="form-container">
  <form [formGroup]="form">
    <div
      appDragDrop
      *ngIf="isLoading || !file"
      (click)="fileInput.click()"
      (fileDropped)="addFile($event)"
      class="dd-container">
      <input hidden type="file" (change)="addFile($event)" #fileInput />
      <mat-icon *ngIf="!isLoading" class="upload-icon"> cloud_upload </mat-icon>
      <dasch-swiss-app-progress-indicator *ngIf="isLoading"></dasch-swiss-app-progress-indicator>
      <p class="title">
        Upload file<br />
        <span class="mat-subtitle-2"
          >The following file types are supported:<br />
          <span *ngFor="let item of allowedFileTypes; let last = last"
            >{{ item }}
            <span *ngIf="!last">,&nbsp;</span>
          </span>
        </span>
      </p>
      <div class="bottom-line">Drag and drop or click to upload</div>
    </div>

    <ng-container *ngIf="!isLoading && file">
      <div *ngIf="thumbnailUrl" class="thumbnail">
        <img [src]="thumbnailUrl" alt="thumbnail" />
      </div>

      <div class="files-list">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Size</th>
              <th>Last Modified Date</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ file.name }}</td>
              <td>{{ convertBytes(file.size) }}</td>
              <td>{{ convertDate(file.lastModified) }}</td>
              <td>
                <button mat-icon-button class="delete-file" title="delete file" (click)="deleteAttachment()">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </form>
</div>
