<div class="warning">
  <div class="container">
    <div class="icon">
      <mat-icon>warning</mat-icon>
    </div>
    <div class="message">
      <p class="top">{{ warningMessages[0] }}</p>
      <br />
      <p class="bottom">{{ warningMessages[1] }}</p>
    </div>
  </div>
</div>
<app-upload
  #upload
  [representation]="representation"
  [attachedProject]="attachedProject"
  (fileInfo)="setFileValue($event)"
  (forceReload)="closeDialog.emit(undefined);"></app-upload>

<div class="form-panel form-action">
  <span>
    <button mat-button type="button" (click)="closeDialog.emit()">{{ 'form.action.cancel' | translate }}</button>
  </span>
  <span class="fill-remaining-space"></span>
  <span>
    <button mat-raised-button type="submit" [disabled]="!fileValue" [color]="'primary'" (click)="saveFile()">
      {{ 'form.action.submit' | translate }}
    </button>
  </span>
</div>
